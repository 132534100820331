












































































import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import Table from "../components/commons/tables/Table.vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Routes } from "../enums";
import { FilterOperatorEnum, TableFilterPayload, TableOptions } from "../types";
import {
  TableGetters,
  TablesActions,
  TablesNamespaces,
} from "../store/modules/tables";
import {
  Laborer,
  LaborerEventListQuery,
  LaborerEventListQueryVariables,
  LaborerEventsFilterEnum,
  LaborerEventTypeEnum,
  Operation,
  OperationStatusEnum,
  Ticket,
  TicketFilterEnum,
} from "../types/gqlGeneratedPrivate";
import { toCZK } from "../filters/money";
import {
  formatRangeOfDates,
  toLocalDate,
  toLocalDateTime,
} from "../filters/datetime";
import { DateTime } from "luxon";
import { api } from "../api";
import { apiClient } from "../utils";

@Component({
  filters: {
    toLocalDateTime,
    toLocalDate,
    toCZK,
  },
  components: {
    Button,
    RoundButton,
    Table,
    ActionsWrapper,
  },
})
export default class LaborerDetailTicketsPage extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  data!: Laborer;

  @Getter(`${TablesNamespaces.LaborerTicketsTable}/${TableGetters.GetTotal}`)
  total!: number;

  currentStatus = "";

  currentDate = DateTime.fromISO(
    this.$route.params.date ?? DateTime.now().toISODate(),
    { zone: "UTC" }
  ).startOf("day");

  tableOptions: TableOptions<Ticket, TicketFilterEnum> = {
    defaultSortBy: ["number"],
    defaultFilter: [
      {
        filter: {
          column: TicketFilterEnum.Operationlaborer,
          operator: FilterOperatorEnum.Equal,
          values: [this.$route.params.id.toString()],
        },
      },
      {
        filter: {
          column: TicketFilterEnum.Estimatetodate,
          operator: FilterOperatorEnum.GreaterThanOrEqual,
          values: [this.currentDate.startOf("day").setZone("utc").toISO()],
        },
      },
      {
        filter: {
          column: TicketFilterEnum.Estimatetodate,
          operator: FilterOperatorEnum.LowerThanOrEqual,
          values: [this.currentDate.endOf("day").setZone("utc").toISO()],
        },
      },
    ],
    headers: [
      {
        text: "Č. zakázky",
        sortable: true,
        align: "start",
        value: "number",
      },
      {
        text: "Odběratel",
        sortable: true,
        align: "start",
        value: "doctor",
      },
      {
        text: "Laborant",
        sortable: true,
        align: "start",
        value: "laborer",
      },
      {
        text: "Pacient",
        sortable: true,
        align: "start",
        value: "patient",
      },
      {
        text: "Zadáno",
        sortable: true,
        align: "start",
        value: "created",
      },
      {
        text: "Předání",
        sortable: true,
        align: "start",
        value: "process",
      },
      {
        text: "Cena",
        sortable: true,
        align: "start",
        value: "price",
      },
      {
        text: "Stanoviště",
        sortable: false,
        align: "start",
        value: "operations",
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.LaborerTicketsTable,
  };

  detail(ticket: Ticket): void {
    this.$router.push({
      name: Routes.TicketDetail,
      params: { id: ticket.id.toString() },
    });
  }

  get laborerLimit() {
    return this.data?.ticketLimit;
  }

  @Watch("data", { immediate: true })
  onDataPopulated(newLaborerData: Laborer) {
    if (newLaborerData?.id) {
      this.fetchLaborerEvents(newLaborerData.id);
      this.refreshTable();
    }
  }

  async fetchLaborerEvents(id: number) {
    const { data } = await apiClient.callGraphqlPrivate<
      LaborerEventListQuery,
      LaborerEventListQueryVariables
    >({
      ...api.laborerEvents.laborerEventList,
      variables: {
        input: {
          filter: [
            {
              filter: [
                {
                  column: LaborerEventsFilterEnum.Laborer,
                  operator: FilterOperatorEnum.Equal,
                  values: [id.toString()],
                },
              ],
            },
          ],
        },
      },
    });

    if (data?.laborerEvents?.items) {
      const events = data?.laborerEvents?.items;
      const currentEvent = events.find((event) => {
        const from = DateTime.fromISO(event.fromDate);
        const to = DateTime.fromISO(event.toDate);
        const now = DateTime.now();
        // https://moment.github.io/luxon/#/math?id=comparing-datetimes
        return from.startOf("day") <= now && now <= to.endOf("day");
      });
      if (currentEvent) {
        let reason = "NEMOC";
        if (currentEvent.type === LaborerEventTypeEnum.Vacation) {
          reason = "DOVOLENÁ";
        }
        const datesRange = formatRangeOfDates(
          new Date(currentEvent.fromDate),
          new Date(currentEvent.toDate)
        );
        this.currentStatus = `${reason} ${datesRange}`;
      } else {
        this.currentStatus = "AKTIVNÍ";
      }
    }
  }

  currentOperationLaborer(operations: Operation[]): string {
    const inProgressOperation = operations.find(
      (o) => o.status === OperationStatusEnum.InProgress
    );
    if (inProgressOperation) {
      const firstname = inProgressOperation.laborer.firstname;
      const surname = inProgressOperation.laborer.surname;
      return `${firstname} ${surname}`;
    }
    return "";
  }

  @Action(TablesActions.Filter, {
    namespace: TablesNamespaces.LaborerTicketsTable,
  })
  filterTable!: (payload: TableFilterPayload) => Promise<void>;

  prev(): void {
    this.currentDate = this.currentDate.minus({ days: 1 });
    this.refreshTable();
  }

  next(): void {
    this.currentDate = this.currentDate.plus({ days: 1 });
    this.refreshTable();
  }

  today(): void {
    this.currentDate = DateTime.now().startOf("day");
    this.refreshTable();
  }

  get isToday(): boolean {
    return this.currentDate.hasSame(DateTime.now(), "day");
  }

  refreshTable() {
    this.filterTable({
      namespace: TablesNamespaces.LaborerTicketsTable,
      filter: [
        {
          filter: {
            column: TicketFilterEnum.Operationlaborer,
            operator: FilterOperatorEnum.Equal,
            values: [this.data.id.toString()],
          },
        },
        {
          filter: {
            column: TicketFilterEnum.Estimatetodate,
            operator: FilterOperatorEnum.GreaterThanOrEqual,
            values: [this.currentDate.startOf("day").setZone("utc").toISO()],
          },
        },
        {
          filter: {
            column: TicketFilterEnum.Estimatetodate,
            operator: FilterOperatorEnum.LowerThanOrEqual,
            values: [this.currentDate.endOf("day").setZone("utc").toISO()],
          },
        },
      ],
    });
  }
}
