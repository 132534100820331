var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex flex-wrap align-center gap-4"},[_c('span',{staticClass:"info-bar d-inline-flex justify-space-between"},[_c('span',{staticClass:"mr-8"},[_c('span',{staticClass:"inline-label mr-2"},[_vm._v("Dnešní zakázky:")]),_c('span',[_vm._v(_vm._s(_vm.total))])]),_c('span',{staticClass:"mr-8"},[_c('span',{staticClass:"inline-label mr-2"},[_vm._v("Aktuální stav:")]),_c('span',[_vm._v(_vm._s(_vm.currentStatus))])]),_c('span',{staticClass:"mr-8"},[_c('span',{staticClass:"inline-label mr-2"},[_vm._v("Limit:")]),_c('span',[_vm._v(_vm._s(_vm.laborerLimit))])]),_c('span',[_c('span',{staticClass:"inline-label mr-2"},[_vm._v("Nad limitem:")]),_c('span',[_vm._v(_vm._s(Math.max(_vm.total - _vm.laborerLimit, 0)))])])]),_c('span',{staticClass:"ml-auto"},[_c('Button',{staticClass:"mx-4",attrs:{"outlined":"","color":"secondary","disabled":_vm.isToday},on:{"click":_vm.today}},[_vm._v(" Dnes ")]),_c('RoundButton',{attrs:{"icon":"chevron-left"},on:{"click":_vm.prev}}),_c('span',{staticClass:"current-date mx-2"},[_vm._v(_vm._s(_vm._f("toLocalDate")(_vm.currentDate.toISODate())))]),_c('RoundButton',{attrs:{"icon":"chevron-right"},on:{"click":_vm.next}})],1)])])],1)],1),_c('Table',{attrs:{"table-options":_vm.tableOptions},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('ActionsWrapper',[_c('RoundButton',{attrs:{"icon":"pencil"},on:{"click":function () { return _vm.detail(item); }}})],1)]}},{key:"doctor",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.companyName)+" ")]}},{key:"laborer",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.firstname)+" "+_vm._s(value.surname)+" ")]}},{key:"patient",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.firstname)+" "+_vm._s(value.surname)+" ")]}},{key:"created",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("toLocalDateTime")(value))+" ")]}},{key:"process",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("toLocalDateTime")(value.deadline))+" ")]}},{key:"price",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("toCZK")(value))+" ")]}},{key:"operations",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.currentOperationLaborer(value))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }